/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { parse } from 'query-string';
import React from 'react';
import Cookies from 'universal-cookie';

import { whitelistQueryParams } from './data-gatsby-api';
import { getMobileOperatingSystem, setCookieExpiration } from './src/helpers';
import {
  appendScriptVeritonic,
  appendThirdPartyScriptsAsync,
  appendThirdPartyScriptsSync,
  handleScriptEqualWeb,
} from './src/helpers/thirdPartyScripts';
import './src/styles/global.css';

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer');
    console.log('IntersectionObserver polyfilled');
  }
};

export const onInitialClientRender = () => {
  setCookieTracking();
  appendThirdPartyScriptsSync();

  if (process.env.NODE_ENV === 'production') {
    window.addEventListener('keydown', appendThirdPartyScriptsAsync, {
      once: true,
    });
    window.addEventListener('pointerover', appendThirdPartyScriptsAsync, {
      once: true,
    });
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (process.env.NODE_ENV === 'production') {
    handleScriptEqualWeb({
      pathname: location.pathname,
      prevPathname: prevLocation?.pathname,
    });
  }

  if (
    process.env.NODE_ENV === 'production' &&
    !prevLocation &&
    (location.pathname === '/' || location.pathname === '/fr/')
  ) {
    appendScriptVeritonic();
  }
};

export const wrapRootElement = ({ element }) => {
  /**
   * @type {import('launchdarkly-react-client-sdk').ProviderConfig}
   */
  const launchDarklySettings = {
    clientSideID: process.env.GATSBY_LAUNCHDARKLY_CLIENT_SIDE_ID || 'MISSING_RELEASE_FLAG_KEY',
    options: {
      bootstrap: require('./src/lib/launchDarkly/defaultFlags.js').defaultLDFlags,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  };

  const App = () => <>{element}</>;
  const LDProvider = withLDProvider(launchDarklySettings)(App);

  return <LDProvider />;
};

const setCookieTracking = () => {
  let paramsDefault = {};

  if (window?.location?.pathname.includes('/paid')) {
    const os = getMobileOperatingSystem();
    const deviceUtmCampaign = os === 'unknown' ? 'desktop' : os;

    let srcUtmSource = '';
    if (window?.location?.pathname.includes('/paid/google')) {
      srcUtmSource = 'google';
    } else if (window?.location?.pathname.includes('/paid/facebook')) {
      srcUtmSource = 'facebook';
    }

    paramsDefault = {
      utm_source: `${srcUtmSource}_mobile_web`,
      utm_campaign: `${deviceUtmCampaign}_mobile_web`,
      wbraid: 'wbraid',
    };
  }

  let paramsFilteredHash = {};
  let paramsFilteredSearch = { ...paramsDefault };
  const cookies = new Cookies();

  if (window?.location?.hash) {
    const queryParamsHash = parse(window.location.hash);
    for (const param in queryParamsHash) {
      if (whitelistQueryParams.hash[param]) {
        paramsFilteredHash = {
          ...paramsFilteredHash,
          [param]: queryParamsHash[param],
        };
      }
    }
  }

  if (window?.location?.search) {
    const queryParamsSearch = parse(window.location.search);
    for (const param in queryParamsSearch) {
      if (whitelistQueryParams.search[param]) {
        paramsFilteredSearch = {
          ...paramsFilteredSearch,
          [param]: queryParamsSearch[param],
        };
      }
    }
  }

  if (Object.keys(paramsFilteredHash).length !== 0 || Object.keys(paramsFilteredSearch).length !== 0) {
    const dateExpiry = setCookieExpiration(30);
    cookies.set(
      'queryParamsSite',
      {
        hash: paramsFilteredHash,
        search: paramsFilteredSearch,
        dateSet: new Date(),
      },
      {
        expires: dateExpiry,
      },
    );
  }
};
