import { useState, useEffect } from 'react';

export default function useScrolled() {
  const [scrollY, setScrollY] = useState<number>(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handler = () => setScrollY(window.scrollY);
      window.addEventListener('scroll', handler);
      return () => window.removeEventListener('scroll', handler);
    }
  }, []);

  return scrollY;
}
