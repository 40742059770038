/**
 * This is used to define the default feature flags until the connection to LaunchDarkly is completed.
 * @type {import('launchdarkly-react-client-sdk').LDFlagSet} Name of the feature flag and its default value.
 */
const defaultLDFlags = {
  'page.always-go-to-app': true,
  'credit-building.enable-variant-hero': null,
  'home.enable-variant-hero': null,
  'experiments.fake-ab-test': null,
};

module.exports.defaultLDFlags = defaultLDFlags;
